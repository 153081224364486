<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';
import InputMask from 'primevue/inputmask';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import CircleIcon from '~/components/design-system/icons/CircleIcon.vue';
import { useAuthStore } from '~/stores/auth.store';

import GenericError from '~/components/user-interface/generic-error.vue';
import { useSiteStore } from '~/stores/site.store';

const formLoading: globalThis.Ref<boolean> = ref(false);
const { $authService, $storageService } = useServices();
const site = useSiteStore();
const authStore = useAuthStore();

const countryCode = site.region;

const form = ref();
const token = ref(null);
const tokenFormatted = ref(null);

const otpValid: globalThis.Ref<boolean> = ref(false);

const errorCode: Ref<boolean> = ref(false);

const model = ref({
  password: '',
  password_confirmation: '',
});

const navigateBack = () => {
  site.activateModal('passwordResetStepOne');
};

const navigateToSuccess = () => {
  site.activateModal('passwordResetStepSuccess');
};

function verifyOTP() {
  let otp = token.value;
  otp = otp.replace(/ +/g, '');
  if (!otp.includes('_') && otp.length > 4) {
    otpValid.value = true;
    tokenFormatted.value = otp;
  } else {
    otpValid.value = false;
  }
}

// Submit new password
const confirmPasswordChange = () => {
  errorCode.value = false;
  formLoading.value = true;
  const token = tokenFormatted.value;
  const password = model.value['password'];

  $authService
    .passwordResetConfirm({
      password,
      login: authStore.passwordResetMobileNumber,
      countryCode,
      token,
    })
    .then((data) => {
      if (data?.isSuccessful) {
        // track username
        $storageService.removeCookie({ key: 'un' });
        // redirect to otp success
        navigateToSuccess();
      } else {
        errorCode.value = data?.error?.code ?? 'general';
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};

onMounted(() => {
  // removing both pseudo input elements to prevent user manipulation
  //setTimeout to allow google chrome to autofill
  setTimeout(() => {
    const elements = document.getElementsByName('removePseudo');
    [...elements].forEach((el) => {
      el.remove();
    });
  }, 500);
});
</script>
<template>
  <div class="px-4 py-2 enter-otp">
    <div class="text-base font-bold">
      <p class="mt-2 mb-1 text-black dark:text-white">
        {{ $t('password-management') }}
      </p>
    </div>

    <div class="text-xs text-black dark:text-white">
      <p>
        {{ $t('otp-body-instructions') }}
      </p>
    </div>

    <div class="field col-12 mb-0 p-0">
      <!-- Creating two pseudo input elements for google chrome to autofill  -->
      <input
        type="email"
        style="opacity: 0; position: absolute"
        id="username"
        name="removePseudo"
      />
      <input
        type="password"
        style="opacity: 0; position: absolute"
        name="removePseudo"
      />

      <InputMask
        v-model="token"
        mask="9   9   9   9   9"
        placeholder="_   _   _   _   _"
        @update:modelValue="verifyOTP()"
        autocomplete="one-time-code"
        class="w-full otp-token text-lg cursor-pointer"
      />
    </div>
  </div>
  <div class="px-4 py-2">
    <Vueform
      ref="form"
      :float-placeholders="true"
      :endpoint="false"
      class="mt-1"
      :model-value="model"
      sync
    >
      <template #empty>
        <VueformPasswordElement
          :key="'password'"
          :name="'password'"
          :rules="[
            'required',
            'confirmed',
            'regex:/^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$/',
          ]"
          :messages="{ regex: $t('formErrors.password') }"
          :display-feedback="true"
          :debounce="500"
          class="mb-3"
        />
        <VueformPasswordElement
          :key="'password_conf'"
          :name="'password_confirmation'"
          labelValue="confirm-password"
          placeholderValue="placeholder.confirm-password"
          :display-feedback="false"
          rules="required"
        />
      </template>
    </Vueform>

    <div class="field col-12 mb-0 py-0">
      <p class="my-1 flex flex-row justify-content-left">
        <CircleIcon
          v-if="!(form?.validated && !form?.invalid)"
          class="w-4 circle-icon"
        />
        <CheckCircleIcon
          v-else
          class="text-primary-blue-700 icon-success--border w-4 circle-icon"
        />
        <span
          class="text-xs font-light"
          :class="
            !(form?.validated && !form?.invalid)
              ? 'text-red-600'
              : 'dark:text-white text-dark-700'
          "
        >
          {{ $t('Passwords-must-match-and-be-valid') }}
        </span>
      </p>
    </div>

    <div class="field-col-12 mb-0 pt-0 flex justify-center">
      <GenericError
        v-if="errorCode"
        class="mt-3 max-w-72 mx-auto"
        state="danger"
      >
        {{ $t(`jpc-update-password-error-` + errorCode) }}
      </GenericError>
    </div>
  </div>

  <div
    class="field col-12 mb-0 bg-light-200 dark:bg-dark-900 text-sm flex flex-row justify-center p-2"
  >
    <Button
      class="m-2 w-full uppercase justify-center text-sm"
      group="secondary"
      @click="navigateBack()"
    >
      {{ $t('back') }}
    </Button>
    <Button
      class="m-2 uppercase w-full justify-center text-sm"
      @click="confirmPasswordChange()"
      :disabled="!(form?.validated && !form?.invalid && otpValid)"
    >
      {{ $t('verify') }}
    </Button>
  </div>
  <LazyUserInterfaceGenericLoader v-if="formLoading" container />
</template>
<style scoped>
.circle-icon {
  margin-right: 5px;
}
</style>
<style>
/* make underscore placeholders persistent */
input.otp-token {
  font-size: 18px;
  letter-spacing: 14px;
  padding-left: 20px;
}
</style>
